<template>
  <div class="d-flex">
    <ScheduleXCalendar
      v-resize="onResize"
      :calendar-app="calendarApp"
      :custom-components="customComponents"
    />
  </div>
  <DialogVacation />
  <DialogTimeSlot />
  <v-dialog
    v-model="dialogYouNeedProfilesAndAddress"
    persistent
    :min-width="xs ? 300 : 500"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{
          $t('dashboard.time-slot.dialogYouNeedProfileAndAddress.title')
        }}</span>
      </v-card-title>

      <v-card-text class="d-flex flex-column ga-5 px-4 px-sm-10 pt-10">
        <span>{{
          $t('dashboard.time-slot.dialogYouNeedProfileAndAddress.message')
        }}</span>
      </v-card-text>
      <v-card-actions
        class="pr-7 pl-5 ga-4 d-flex flex-wrap justify-end align-center pb-8"
      >
        <router-link :to="{ name: 'DashboardAddress' }">
          <v-btn class="px-6" variant="outlined" color="blue-darken-1">
            {{
              $t(
                'dashboard.time-slot.dialogYouNeedProfileAndAddress.goToAddresses',
              )
            }}
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'DashboardProfile' }">
          <v-btn class="px-6" variant="flat" color="blue-darken-1">
            {{
              $t(
                'dashboard.time-slot.dialogYouNeedProfileAndAddress.goToProfiles',
              )
            }}
          </v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/index.css'
import { ScheduleXCalendar } from '@holitime/schedule-x-vue'
import '@holitime/schedule-x-theme-default/dist/date-picker.css'
import { calendarApp } from '@/components/calendar/calendarApp'
import { CustomComponents } from '@holitime/schedule-x-shared'
import CustomSidebarWrapper from '@/components/calendar/CustomSidebarWrapper.vue'
import { useDisplay } from 'vuetify'
import { useSidebarState } from '@/components/calendar/calendarState'
import DialogVacation from '@/components/calendar/DialogVacation.vue'
import { onMounted, ref, computed } from 'vue'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import EventBus from '@/utils/eventBus'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import { InitTimeSlotParams } from '@/store/timeslot/timeSlotEvent'
import CustomTimeGridEvent from '@/components/calendar/CustomTimeGridEvent.vue'
import { useUserStore } from '@/store/user/userStore'
import { useAddressStore } from '@/store/address/addressStore'
import DialogTimeSlot from '@/components/calendar/DialogTimeSlot.vue'
import CustomMonthGridEvent from '@/components/calendar/CustomMonthGridEvent.vue'
import CustomMonthAgenda from '@/components/calendar/CustomMonthAgenda.vue'
import createHttpClient from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import { ApiResponse } from '@/api/api'
const httpClient = createHttpClient()
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import { useProfileStore } from '@/store/profile/profileStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { useVacationEventStore } from '@/store/vacation/vacationEventStore'

const { t } = useI18n()
const { initTimeSlots } = useTimeSlotStore()
const appointmentReasonStore = useAppointmentReasonStore()
const userStore = useUserStore()
const addressStore = useAddressStore()
const dialogStore = useDialogTimeSlotStore()
const vacationStore = useVacationEventStore()

EventBus.on(
  'initTimeSlots',
  (
    {
      profileIds,
      appointmentReasonIds,
      addressIds,
      ownerId,
      start,
      end,
    }: InitTimeSlotParams = {
      profileIds: profileStore.profiles.map(p => p.id),
      appointmentReasonIds: appointmentReasonStore.appointmentReasons.map(
        a => a.id,
      ),
      addressIds: addressStore.addresses.map(a => a.id),
      start: null,
      end: null,
    },
  ) => {
    initTimeSlots({
      profileIds,
      appointmentReasonIds,
      addressIds,
      ownerId,
      start,
      end,
    })
  },
)

const dialogYouNeedProfilesAndAddress = ref(false)
const openDialogYouNeedProfilesAndAddress = () => {
  dialogYouNeedProfilesAndAddress.value = true
}
const closeDialogYouNeedProfilesAndAddress = () => {
  dialogYouNeedProfilesAndAddress.value = false
}
const router = useRouter()
const profileStore = useProfileStore()
// const authorizeGoogleCalendar = async () => {
//     try {
//         const resp: ApiResponse<[]> = await httpClient.get(
//           '/connect/google'
//         )
//         if (resp.status != 200) throw Error(JSON.stringify(resp.data))
//         console.log(resp.data);
//         window.location.href=resp.data?.url;

//       } catch (error) {
//         console.error(error)
//       }
// }

// const handleGoogleCallback = async () => {
//     try {
//         // Extraire le code d'authentification de l'URL
//         const query = new URLSearchParams(window.location.search);
//         const code = query.get('code');

//         if (code) {
//             // Envoyer le code d'authentification au backend pour obtenir les informations utilisateur
//             const resp = await httpClient.get(`/connect/google/check?code=${encodeURIComponent(code)}`);
//             if (resp.status != 200) throw new Error(JSON.stringify(resp.data));

//             console.log('Google OAuth callback processed successfully');

//             // Rediriger l'utilisateur après le traitement
//             router.push('/dashboard/time-slot'); // Ou vers une autre route
//         }
//     } catch (error) {
//         console.error('Error during Google OAuth callback:', error);
//     }
// }

const isTimeSlot = computed(
  () => router.currentRoute.value.name === 'DashboardTimeSlot',
)
const isAppointment = computed(
  () => router.currentRoute.value.name === 'DashboardAppointment',
)

// const toggleDialog = async () => {
//   if (isTimeSlot.value) {
//     const { toggleDialogTimeSlot } = useDialogTimeSlotStore()
//     toggleDialogTimeSlot()
//   }
//   if (isAppointment.value) {
//     const { toggleDialog } = useDialogPractitionerAppointmentStore()
//     await toggleDialog()
//   }
// }

onMounted(async () => {
  // await handleGoogleCallback();

  // await userStore.initProfiles()
  await profileStore.initProfiles()
  // await userStore.initAddresses()
  await addressStore.initAddresses()
  if (
    !addressStore.addresses ||
    !addressStore.addresses.length ||
    !profileStore.profiles ||
    !profileStore.profiles.length
  ) {
    openDialogYouNeedProfilesAndAddress()
  }
  initTimeSlots()
  await profileStore.refreshProfiles()
  await addressStore.refreshAddresses()
  await dialogStore.resetDialogTimeSlotCreationProfileAddress()
  // vacationStore.refreshVacations()
  await appointmentReasonStore.refreshAppointmentReasons()
})

const customComponents: CustomComponents = {
  sidebar: CustomSidebarWrapper as unknown as any,
  // dateGridEvent: CustomDateGridEvent as unknown as any,
  timeGridEvent: CustomTimeGridEvent as unknown as any,
  monthGridEvent: CustomMonthGridEvent as unknown as any,
  monthAgendaEvent: CustomMonthAgenda as unknown as any,
}

const { lgAndUp, xs } = useDisplay()
const { sidebarState } = useSidebarState()

const onResize = () => {
  const calendarContainer = document.querySelector('.sx__calendar-content')
  if (!calendarContainer) return
  if (lgAndUp.value && sidebarState.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
  if (
    lgAndUp.value &&
    calendarContainer.classList.contains('hidden-sidebar') &&
    !sidebarState.value
  ) {
    calendarContainer.classList.add('hidden-sidebar')
  }
  if (!lgAndUp.value) {
    calendarContainer.classList.remove('hidden-sidebar')
  }
}
</script>

<style lang="scss" scoped>
@import url('./styleCalendar.css');

.page-header .title {
  font-size: 33px;
  line-height: 46.2px;
  font-weight: 700;
  color: #004838;
}
.page-header .breadcrumb {
  list-style: none;
}

.page-meta .icon {
  width: 16px;
  height: 16px;
}

.page-header .breadcrumb .icon {
  width: 16px;
  height: 16px;
  flex-basis: 16px;
}
.page-header .breadcrumb li {
  font-size: 14px;
  color: #9e9e9e;
}
.page-header .breadcrumb li:not(:last-child) {
  margin-right: 10px;
}
.page-header .breadcrumb li:last-child {
  color: #004838;
  font-weight: 500;
  size: 14px;
  text-decoration: underline;
}

.affiliate-data {
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 500;
  color: #9e9e9e;
  gap: 8px;
  justify-items: end;
}

.aff-link {
  padding: 10px 16px 10px 16px;
  background: #12c29b;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  line-height: 16.8px;
  order: 2;
}
.aff-link .home-link {
  margin-right: 10px;
}

.follow {
  list-style: none;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 500;
  color: #9e9e9e;
  gap: 8px;
}

.follow .icon {
  cursor: pointer;
}

.affi-status {
  margin-top: 12px;
}

.affi-status-head {
  gap: 12px;
}

.rounded-bg {
  background: rgba(18, 194, 155, 0.2);
  height: 31px;
  width: 31px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.control-btn {
  padding: 4px 16px;
  gap: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 40px;
  display: flex;
  align-items: center;
  line-height: 21px;
  font-size: 14px;
  min-height: 36px;
  color: rgba(158, 158, 158, 1);
  cursor: pointer;
}

.v-menu > .v-overlay__content > .v-card,
.v-menu > .v-overlay__content > .v-sheet,
.v-menu > .v-overlay__content > .v-list {
  background: rgb(var(--v-theme-surface)) !important;
}

// .timeSlotDialogModal > .v-overlay__content {
//   width: max-content !important;
//   max-width: max-content !important;
// }
</style>
